<template>
  <div>
    <!-- Header: Personal Info -->
    <div class="d-flex">
      <feather-icon icon="UserIcon" size="19" />
      <h4 class="mb-0 ml-50">
        {{ $t("userPlants") }}
      </h4>
    </div>

    <!-- Form: Personal Info Form -->
    <b-form class="mt-1">
      <b-row>
        <!-- Field: Username -->
        <b-col
          v-for="item in userData.idsarray"
          :key="item.id"
          cols="12"
          md="4"
        >
          <b-form-checkbox :id="item.plantname" v-model="item.selected">
            {{ item.plantname }}
          </b-form-checkbox>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import { BRow, BCol, BForm, BFormCheckbox } from "bootstrap-vue";

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BFormCheckbox,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data: function () {
    return {
      userData: this.value,
    };
  },
  methods: {
    async updateUser(user) {
      this.$emit("input", this.userData);
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
