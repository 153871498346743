<template>
  <div>
    <user-edit></user-edit>
  </div>
</template>
<script>
import UserEdit from "./UserEdit/UsersEdit.vue";
export default {
  components: {
    UserEdit,
  },
  mounted() {},
};
</script>
<style></style>
