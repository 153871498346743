<template>
  <component :is="userData === undefined ? 'div' : 'b-card'">
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="userData === undefined">
      <h4 class="alert-heading">
        {{ $t("xErrorFetch", { value: $t("user") }) }}
      </h4>
      <div class="alert-body">
        {{ $t("xNotFound", { value: $t("user") }) }}
        <b-link class="alert-link" :to="{ name: 'apps-users-list' }" />
      </div>
    </b-alert>

    <user-edit-tab-information
      :key="'info' + userData.id"
      :user-data="userData"
      class="mt-2 pt-75"
      @update-user="updateUser"
    />
    <user-edit-tab-plant
      :key="'plant' + userData.id"
      v-model="userData"
      class="mt-2 pt-75"
    />
  </component>
</template>

<script>
import { BTab, BTabs, BCard, BAlert, BLink } from "bootstrap-vue";
import router from "@/router";
import store from "@/store";
import UserEditTabInformation from "./UserEditTabInformation.vue";
import UserEditTabPlant from "./UserEditTabPlant.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,

    UserEditTabInformation,
    UserEditTabPlant,
  },
  data: function () {
    return {
      userData: {},
    };
  },
  async mounted() {
    var userdata = await store.dispatch("usersModule/fetchUser", {
      id: router.currentRoute.params.id,
    });
    this.userData = Object.assign({}, userdata);
  },
  methods: {
    async updateUser(user) {
      var result = await this.$store.dispatch("usersModule/updateUser", user);
      if (result == "OK")
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: this.$t("successful"),
            icon: "CheckIcon",
            variant: "success",
            text: this.$t("updated", { type: this.$t("user") }),
          },
        });
      else
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: this.$t("unsuccessful"),
            icon: "AlertTriangleIcon",
            variant: "danger",
            text: this.$t("notUpdated", { type: this.$t("user") }),
          },
        });
    },
  },
};
</script>

<style></style>
